import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "./style.css"

const Navigation = ({ pages }) => (
  <nav className="wrapper">
    {pages.map((page, i) => {
      if (i === 0) {
        return null
      }
      return (
        <AniLink
          fade
          duration={0.2}
          activeClassName="active"
          className="nav-item"
          key={page.id}
          to={`${page.slug}/`}
        >
          {page.title}
        </AniLink>
      )
    })}
  </nav>
)

export default Navigation
