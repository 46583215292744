import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Header from '../Header';
import Navigation from '../Navigation';
import './style.css';

const Layout = ({ children }) => {
  const { contentfulSiteLayout: { title, heading, pages, footer } } = useStaticQuery(graphql`
    query SiteTitleQuery {
      contentfulSiteLayout {
        title
        heading
        pages {
          id
          slug
          title
        }
        footer {
          json
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={heading} />
      <Navigation pages={pages} />
        <main className="wrapper">{children}</main>
        <footer class="wrapper">
          <div class="credit">
            &copy; {title} {new Date().getFullYear()}
            {documentToReactComponents(footer.json)}
          </div>
        </footer>
    </>
  );
}

export default Layout;
