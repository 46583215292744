import { Link } from "gatsby"
import React from "react"

import './style.css';

const Header = ({ siteTitle }) => (
  <header className="wrapper">
    <h1 className="title">
      <Link to="/" >
        {siteTitle}
      </Link>
    </h1>
  </header>
)

export default Header
